import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceConfig } from './maintenance.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../@theme/services/loading.service';

@Component({
  selector: 'ngx-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  config: MaintenanceConfig | null = null;

  private subscriptions = new Subscription();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.loadingService.loaderOn();
    this.subscribeRoute();
  }

  get content() {
    return this.config && this.config.template ? this.config.template : '';
  }

  subscribeRoute() {
    this.subscriptions.add(
      this.activatedRoute.data.subscribe(value => {
        if (!value.config.maintenance) {
          this.router.navigate(['/']);
        } else {
          this.loadingService.loaderOff();
          this.config = value.config;
        }
      }),
    );
  }
}
