import { IWallet } from '../interfaces/wallet.interface';

export class WalletModel {
  readonly address: string;
  readonly addressName: string;
  readonly currency: string;
  readonly id: string;
  readonly isConfirm: boolean;
  readonly isSaved: boolean;
  readonly merchantId: string;
  readonly destinationTag: string | null;

  constructor(input: IWallet) {
    this.address = input.address;
    this.addressName = input.addressName;
    this.currency = input.currency;
    this.id = input.id;
    this.isConfirm = input.isConfirm;
    this.isSaved = input.isSaved;
    this.merchantId = input.merchantId;
    this.destinationTag = input.destinationTag || null;
  }
}
