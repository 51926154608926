import { Injectable } from '@angular/core';
import { BaseApiService } from '../../@core/services/base-api.service';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { finalize, first, map, tap } from 'rxjs/operators';
import { IWallet } from '../../shared/interfaces/wallet.interface';
import { WalletModel } from '../../shared/models/wallet.model';
import {ISettlementDetails} from "../../shared/interfaces/settlement-details.interface";

export interface SettlementsFilters {
  currency?: string;
  status?: string;
  dateFrom?: string;
  dateTo?: string;
  timeZone?: number;
  page?: number;
  pageSize?: number;
  search?: string;
}

export interface SettlementRequestCreatePayload {
  amount: string;
  currency: string;
}

export enum SettlementStatus {
  Requested = 'requested',
  Broadcasted = 'broadcasted',
  OrderPlaced = 'orderPlaced',
  Performed = 'performed',
  Failed = 'failed',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Declined = 'declined',
  InReviewValue = 'in-review'
}

export const SETTLEMENT_SUCCESS_STATUSES = [SettlementStatus.Performed];

export const SETTLEMENT_FAIL_STATUSES = [
  SettlementStatus.Cancelled,
  SettlementStatus.Failed,
  SettlementStatus.Declined,
  SettlementStatus.Expired,
];

export interface Settlement {
  id: string;
  amountCrypto: string;
  amountUsd: string;
  exchange: string | null;
  txId: string | null;
  minerFee: string | null;
  orderId: string | null;
  depositMemo: string | null;
  depositAddress: string | null;
  cryptoCurrency: string;
  status: SettlementStatus;
  created: string;
  updated: string;
  meta: {
    userName: string;
  };
}

interface SettlementRequestBatch {
  total: number;
  batch: Settlement[];
}

@Injectable()
export class SettlementsApiService {
  public readonly history$ = new ReplaySubject<SettlementRequestBatch>();
  public readonly created$ = new Subject<Settlement>();
  public readonly cancelled$ = new Subject();

  constructor(private readonly baseApi: BaseApiService) {
    this.history$.pipe(
      finalize(() => {
        this.history$.complete();
      }),
    );
  }

  load(filters: SettlementsFilters, observer?: Observer<SettlementRequestBatch>): void {
    this.baseApi
      .get('settlements', filters)
      .pipe(first())
      .pipe(
        tap(value => {
          this.history$.next(value);
        }),
      )
      .subscribe(observer);
  }

  create(data: SettlementRequestCreatePayload) {
    return this.baseApi
      .post<Settlement>('settlements', data)
      .pipe(first())
      .pipe(
        tap(value => {
          this.created$.next(value);
        }),
      );
  }

  cancel(id: string) {
    return this.baseApi
      .put(`settlements/cancel/${id}`)
      .pipe(first())
      .pipe(
        tap(value => {
          this.cancelled$.next(value);
        }),
      );
  }

  public getConfirmedWallets(): Observable<WalletModel[]> {
    return this.baseApi.get('settlements/addresses/')
      .pipe(
        first(),
        map((res: IWallet[]) => {
          return res.map((item: IWallet) => new WalletModel(item));
        }),
      );
  }

  public getSavedWallets(): Observable<WalletModel[]> {
    return this.baseApi.get('settlements/addresses/saved/')
      .pipe(
        first(),
        map((res: IWallet[]) => {
          return res.map((item: IWallet) => new WalletModel(item));
        }),
      );
  }

  public putSavedWallet(walletId: string, walletName: {[key: string]: string }): Observable<any> {
    return this.baseApi.put(`settlements/addresses/saved/${walletId}`, walletName);
  }

  public deleteSavedWallet(walletId: string): Observable<any> {
    return this.baseApi.delete(`settlements/address/${walletId}`);
  }

  public getSettlementById(id: string): Observable<ISettlementDetails> {
    return this.baseApi.get(`settlements/${id}`)
  }
 }
