import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BrandsService } from '../services/brands.service';

@Injectable()
export class GetBrandsResolver implements Resolve<any> {
  constructor(private currenciesService: BrandsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.currenciesService.getBrands();
  }
}
