import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../shared/services/auth.service';
import { MaintenanceService } from '../../shared/maintenance/maintenance.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private maintenanceService: MaintenanceService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this.authService.logout();
          location.reload(true);
        } else if (err.status === 503) {
          const { error } = err;

          if (error && typeof error === 'object' && error.reason === 'maintenance') {
            this.maintenanceService.activate();
          }
        }

        return throwError(err);
      }),
    );
  }
}
