import { Component, OnDestroy } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/utils';
import { Subscription } from 'rxjs';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-mainboard-layout',
  templateUrl: 'mainboard.layout.html',
  styleUrls: ['./mainboard.layout.scss'],
})
export class MainboardLayoutComponent implements OnDestroy {
  layout: any = {};
  sidebar: any = {};

  private alive = true;

  currentTheme: string;

  loading: boolean;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
  ) {
    this._subscriptions.add(
      this.stateService
        .onLayoutState()
        .pipe(takeWhile(() => this.alive))
        .subscribe((layout: string) => (this.layout = layout)),
    );

    this._subscriptions.add(
      this.stateService
        .onSidebarState()
        .pipe(takeWhile(() => this.alive))
        .subscribe((sidebar: string) => {
          this.sidebar = sidebar;
        }),
    );

    const isBp = this.bpService.getByName('is');
    this._subscriptions.add(
      this.menuService
        .onItemSelect()
        .pipe(
          takeWhile(() => this.alive),
          withLatestFrom(this.themeService.onMediaQueryChange()),
          delay(20),
        )
        .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
          if (bpTo.width <= isBp.width) {
            this.sidebarService.collapse('menu-sidebar');
          }
        }),
    );

    this._subscriptions.add(
      this.themeService
        .getJsTheme()
        .pipe(takeWhile(() => this.alive))
        .subscribe(theme => {
          this.currentTheme = theme.name;
        }),
    );
  }

  ngOnDestroy() {
    this.alive = false;
    this._subscriptions.unsubscribe();
  }
}
