import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'date' })
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(
    value: any,
    format: string = 'd MMM y HH:mm:ss',
    timezone?: string,
    locale?: string,
  ): string | null {
    return super.transform(value, format, timezone, locale);
  }
}
