import { DepositColorEnum, DepositCustomizationEnum } from '../../enums/deposit-customization.enum';
import { IDepositPageTemplate } from '../../interfaces/deposit-customization.interface';


export const DEPOSIT_COLOR_CONFIG: { [key: string]: string } = {
  [DepositColorEnum.BackgroundColor1]: 'Background main',
  [DepositColorEnum.BackgroundColor2]: 'Background secondary',
  [DepositColorEnum.ColorMain]: 'Color text main',
  [DepositColorEnum.ColorSecondary]: 'Color text secondary',
  [DepositColorEnum.ColorHighlight]: 'Color text highlighted',
  [DepositColorEnum.ColorWarning]: 'Color text warning',
}

export const DEPOSIT_DEFAULT_CONFIG: IDepositPageTemplate = {
  [DepositColorEnum.BackgroundColor1]: '#0c1619',
  [DepositColorEnum.BackgroundColor2]: '#122024',
  [DepositColorEnum.ColorMain]: 'white',
  [DepositColorEnum.ColorSecondary]: '#bfbfbf',
  [DepositColorEnum.ColorHighlight]: '#7eddc1',
  [DepositColorEnum.ColorWarning]: 'red',
  headerEnable: true,
  logo: DepositCustomizationEnum.Logo,
  font: 'Museo Sans',
  name: DepositCustomizationEnum.Default,
  favicon: DepositCustomizationEnum.Favicon,
  pageTitle: DepositCustomizationEnum.PageTitle,
}

export const FONT_LIST = ['Calibre', 'Calibri', 'CourierStd', 'FoundationMono', 'Helvetica', 'Museo Sans', 'OpenSans', 'RobotoMono', 'SofiaPro', 'SpaceMono', 'Titillium'];
