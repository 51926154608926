import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImgCropperService } from './img-cropper.service';
import { CropperType, LOGO_CROP } from '../../interfaces/deposit-customization.interface';

@Component({
  selector: 'ngx-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss']
})
export class ImgCropperComponent implements OnInit{
  @Input() cropperType: CropperType;

  @Output() setLogo: EventEmitter<string> = new EventEmitter<string>();
  @Output() dialogClose: EventEmitter<void> = new EventEmitter<void>();

  public imageChangedEvent: any = this.imgCropperService.getEvent() || '';
  public croppedImage: any = '';
  public ratioList = this.cropperType === LOGO_CROP ? [
    { value: 0, label: 'Off' },
    { value: 1 / 1, label: '1:1' },
    { value: 4 / 3, label: '4:3' },
    { value: 16 / 9, label: '16:9' },
  ] : [];
  public ratioValue = 0;
  public resizeHeight = 50;
  public outputFormat: 'png' | 'ico' = 'png';

  constructor(
    private imgCropperService: ImgCropperService,
  ) {
  }

  public ngOnInit() {
    this.ratioList = this.cropperType === LOGO_CROP ? [
      { value: 0, label: 'Off' },
      { value: 1 / 1, label: '1:1' },
      { value: 4 / 3, label: '4:3' },
      { value: 16 / 9, label: '16:9' },
    ] : [];
    this.ratioValue = this.cropperType === LOGO_CROP ? 0 : 1 / 1;
    this.resizeHeight = this.cropperType === LOGO_CROP ? 50 : 35;
    this.outputFormat = this.cropperType === LOGO_CROP ? 'png' : 'ico';
  }

  public fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
    this.imgCropperService.setImage(event);
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public setImage(): void {
    this.setLogo.emit(this.croppedImage);
  }

  public close(): void {
    this.dialogClose.emit();
  }
}
