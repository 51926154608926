import { NbMenuService } from '@nebular/theme';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-page-404',
  styleUrls: ['page-404.component.scss'],
  templateUrl: 'page-404.component.html',
})
export class Page404Component {
  constructor(private menuService: NbMenuService) {}

  goToHome() {
    this.menuService.navigateHome();
  }
}
