import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
  public spinner = document.getElementById('nb-global-spinner');

  loaderOn() {
    this.spinner.style.display = 'block';
    this.spinner.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  }

  loaderOff() {
    this.spinner.style.display = 'none';
  }
}
