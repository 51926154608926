import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { DepositColorEnum } from '../../enums/deposit-customization.enum';
import { DEPOSIT_COLOR_CONFIG } from '../../configs/deposit-customization/deposit-customization.config';

@Component({
  selector: 'ngx-color-picker',
  templateUrl: './color-picker.component.html',
})
export class ColorPickerComponent implements OnInit {
  @Input() colorData: { type: DepositColorEnum, color: string };

  @Output() setColor: EventEmitter<{ type: DepositColorEnum, color: string }> = new EventEmitter<{ type: DepositColorEnum, color: string }>();
  @Output() dialogClose: EventEmitter<void> = new EventEmitter<void>();

  public pickedColor = '';
  public display = false;
  public depositColorConfig: { [key: string]: string } = DEPOSIT_COLOR_CONFIG;

  public ngOnInit(): void {
    this.pickedColor = this.colorData.color;
    this.display = true;
  }

  public changeComplete({ color: { hex } }: ColorEvent): void {
    this.pickedColor = hex;
  }

  public close(type: boolean = false): void {
    type ?
      this.setColor.emit({ type: this.colorData.type, color: this.pickedColor }) :
      this.dialogClose.emit();
  }
}
