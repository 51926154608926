import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MaintenanceService } from './maintenance.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MaintenanceResolver implements Resolve<any> {
  constructor(private readonly service: MaintenanceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.service.fetch();
  }
}
