import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {SettlementsApiService} from '../../pages/settlements/settlements-api.service';
import {PermissionsService} from "../services/permissions.service";

@Injectable()
export class GetSaveWalletsResolver implements Resolve<any> {
  constructor(
    private settlementsApiService: SettlementsApiService,
    private permissionsService: PermissionsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.permissionsService.userPermissions.settlementRequests) {
      return this.settlementsApiService.getSavedWallets();
    } else {
      return false;
    }
  }
}
