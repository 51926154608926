import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyInCamelCaseToTitle',
})
export class KeyInCamelCaseToTitlePipe implements PipeTransform {
  transform(text: string, changed?: { capital?: [number]; low?: [number]; upper?: [number] }): any {
    const result = text.replace(/([A-Z])/g, ' $1');
    if (!changed) {
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    const resultArr = result.split(' ');

    if (changed.capital) {
      changed.capital.forEach(t => {
        if (resultArr[t - 1] && resultArr[t - 1].length) {
          resultArr[t - 1] = resultArr[t - 1].charAt(0).toUpperCase() + resultArr[t - 1].slice(1).toLowerCase();
        }
      });
    }

    if (changed.low) {
      changed.low.forEach(t => {
        if (resultArr[t - 1]) {
          resultArr[t - 1] = resultArr[t - 1].toLowerCase();
        }
      });
    }

    if (changed.upper) {
      changed.upper.forEach(t => {
        if (resultArr[t - 1]) {
          resultArr[t - 1] = resultArr[t - 1].toUpperCase();
        }
      });
    }

    return resultArr.join(' ');
  }
}
