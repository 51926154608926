import { Injectable } from '@angular/core';

import { PermissionsInterface } from '../interfaces/permissions.interface';

@Injectable()
export class PermissionsService {
  private permissions: PermissionsInterface;

  public get userPermissions() {
    return this.permissions;
  }

  public set userPermissions(permissions: PermissionsInterface) {
    this.permissions = permissions;
  }

  constructor() {}
}
