import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseApiService} from '../../@core/services/base-api.service';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class UtcService {
  public utcList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private utcList$: Observable<string[]> = this.utcList.asObservable();

  constructor(
    private baseApi: BaseApiService,
  ) {
  }

  public getUtcSettings(): Observable<string[]> {
    return this.baseApi.get('report/utc-offsets')
      .pipe(
        map((res: { utcOffsets: string[] }) => res.utcOffsets),
        tap((res: string[]) => {
          this.utcList.next(res);
        })
      );
  }
}
