import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService, StateService } from './utils';

import { BaseApiService } from './services/base-api.service';

export const NB_CORE_PROVIDERS = [AnalyticsService, StateService];

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [BaseApiService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
