import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { IDepositCustomizationForm } from '../../shared/interfaces/deposit-customization.interface';
import { DepositColorEnum } from '../../shared/enums/deposit-customization.enum';

interface ISetStyle {
  type: 'button1' | 'button2';
  colors: IDepositCustomizationForm;
}

@Directive({
  selector: '[setStyle]',
})

export class StyleDirective {
  @Input()
  public set setStyle(data: ISetStyle) {
    this.setStyles(data.type, data.colors);
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
  }

  private setStyles(type: 'button1' | 'button2', colors: IDepositCustomizationForm): void {

    if (type === 'button1') {
      this.setButtonStyle(colors[DepositColorEnum.ColorSecondary], colors[DepositColorEnum.BackgroundColor1]);
    }

    if (type === 'button2') {
      this.setButtonStyle(colors[DepositColorEnum.ColorHighlight], colors[DepositColorEnum.BackgroundColor1]);
    }
  }

  private setButtonStyle(color: string, backgroundColor1: string): void {
    this.elementRef.nativeElement.setAttribute('style', `color: ${color}; border-color: ${color}`);
    this.elementRef.nativeElement.setAttribute('onMouseOver', `this.style.color="${backgroundColor1}";this.style.backgroundColor="${color}"`);
    this.elementRef.nativeElement.setAttribute('onMouseOut', `this.style.color="${color}";this.style.backgroundColor="transparent"`);
  }
}
