import { Injectable } from '@angular/core';

import { BaseApiService } from '../../@core/services/base-api.service';

import { BrandInterface } from '../interfaces/brands.interface';
import { ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class BrandsService {
  /* @deprecated */
  public brands: Array<BrandInterface>;

  public brands$ = new ReplaySubject<BrandInterface[]>();

  constructor(private baseApi: BaseApiService) {}

  getBrands() {
    return this.baseApi.get(`brands`).pipe(
      tap(value => {
        this.brands$.next(value);
      }),
    );
  }

  changeLanguage(brandId: string, lang: string) {
    return this.baseApi.put(`brands/${brandId}`, { lang });
  }
}
