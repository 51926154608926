import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-button-time-reset',
  templateUrl: './button-time-reset.component.html',
  styleUrls: ['./button-time-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonTimeResetComponent {
  @Input() ngModelDate: any;
  @Output() getDefaultTime = new EventEmitter<void>();

  reset() {
    this.getDefaultTime.emit();
  }
}
