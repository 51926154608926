import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[inputNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: InputNumberDirective, multi: true }],
})
export class InputNumberDirective {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateInputNumberFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateInputNumberFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    const reg = /[0-9]+([,.][0-9]+)?/g;

    const isInValid =
      (!c.value && c.value !== 0) ||
      c.value < 0 ||
      c.value.toString().match(reg) === null ||
      c.value.toString().match(reg).length !== 1;
    if (!isInValid) {
      return null;
    } else {
      return {
        inputNumber: {
          valid: false,
        },
      };
    }
  };
}
