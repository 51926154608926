import {Component, Input, OnInit} from '@angular/core';
import {copyTextToClipboard} from '../../../@core/utils/copy-to-clipboard';

@Component({
  selector: 'ngx-copy-btn',
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.scss'],
})
export class CopyBtnComponent implements OnInit {
  @Input()
  value: string | number;
  public clicked: boolean = false;
  private timer: any;

  constructor() {
  }

  ngOnInit() {
  }

  copyToClipboard() {
    copyTextToClipboard(this.value);
    this.clicked = true;

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.clicked = false;
    }, 500);
  }
}
