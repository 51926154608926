import { Injectable } from '@angular/core';
import { BaseApiService } from '../../@core/services/base-api.service';
import { UserInterface } from '../interfaces/user.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private baseApi: BaseApiService) {}

  public get currentUserValue(): UserInterface {
    const token = this.authToken;
    if (token) return this.parseJwt(token);

    return null;
  }

  public get authToken(): string {
    const jwtToken = localStorage.getItem('currentUser');
    if (jwtToken) {
      try {
        return JSON.parse(jwtToken).token;
      } catch (e) {}
    }
    return null;
  }

  login(data: object) {
    return this.baseApi.post(`login`, data).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      }),
    );
  }

  forgot(data: object) {
    return this.baseApi.post('forgot-password', data);
  }

  public resetQrCode(data: object): Observable<any> {
    return this.baseApi.post('reset-qr-code', data);
  }

  restore(token: string, data: object) {
    return this.baseApi.post(`reset-password/${token}`, data);
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  private parseJwt(jwtToken) {
    try {
      const base64Url = jwtToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    } catch (e) {}
    return null;
  }
}
