import { Pipe, PipeTransform } from '@angular/core';
import { TRANSACTION_SUB_STATE_TITLE_MAP } from '../../pages/transactions-history/transactions-history.service';

@Pipe({
  name: 'subStateValuePipe',
})
export class SubStateValuePipe implements PipeTransform {
  transform(value: string): any {
    return TRANSACTION_SUB_STATE_TITLE_MAP[value] || value;
  }
}
