import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GetPermissionsResolver implements Resolve<any> {
  constructor(private permissionsService: PermissionsService, private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;

    if (currentUser) {
      this.permissionsService.userPermissions = currentUser.permissions;
    }
  }
}
