import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ngx-dialog-with-input',
  templateUrl: 'dialog-with-input.html',
  styleUrls: ['dialog-with-input.scss'],
})
export class DialogWithInputComponent {
  public title: string;

  @Input() type: string;
  @Input() value: string;
  @Input() buttonAccept: string;
  @Input() buttonDecline: string;

  @Input() set setTitle(title: string) {
    this.title = title;
  }

  @Output() answer = new EventEmitter<{}>();

  constructor() {}

  close(answer: boolean) {
    this.answer.emit({ value: this.value, answer: answer });
  }
}
