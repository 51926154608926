import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { CurrenciesService } from '../services/currencies.service';
import { Observable } from 'rxjs';

@Injectable()
export class GetCurrenciesResolver implements Resolve<any> {
  constructor(private currenciesService: CurrenciesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable(observer => {
      return this.currenciesService.updateCurrencies(observer);
    });
  }
}
