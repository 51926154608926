import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[buttonSpinner]',
})
export class ButtonSpinnerDirective {

  @Input()
  public set buttonSpinner(show: boolean) {
    this.showSpinner = show;
    this.setSpinner();
  }

  private showSpinner: boolean = false;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
  }

  private setSpinner(): void {
    if (this.showSpinner) {
      this.renderer.addClass(this.elementRef.nativeElement, 'btn-spinner');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'btn-spinner');
    }
  }
}
