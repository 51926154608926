import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseApiService {
  private baseUrl = 'https://crypto-exchange-bo-api.dev.digicode.ua/api/v1/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl + '/';
  }

  public get(url: string, filter?: any, params?: HttpParams): Observable<any> {
    return this.httpClient.get(this.getParams(this.getUrl(url), filter), {
      ...this.httpOptions,
      params,
    });
  }

  public post<T = any>(url: string, body?: any | null): Observable<T> {
    return this.httpClient.post<T>(this.baseUrl + url, body, this.httpOptions);
  }

  public patch(url: string, body?: any | null): Observable<any> {
    return this.httpClient.patch(this.baseUrl + url, body, this.httpOptions);
  }

  public put(url: string, body?: {}): Observable<any> {
    return this.httpClient.put(this.baseUrl + url, body, this.httpOptions);
  }

  public delete(url: string, data?: any): Observable<any> {
    return this.httpClient.request('delete', this.baseUrl + url, {
      body: data,
      headers: this.httpOptions.headers,
    });
  }

  private getUrl(url: string = ''): string {
    return this.baseUrl + url;
  }

  private objToArray(obj, path) {
    if (!path) path = [];
    let result = [];
    for (const i in obj)
      if (typeof obj[i] === 'object') result = result.concat(this.objToArray(obj[i], [].concat(path, [i])));
      else result.push({ path: [].concat(path, [i]), value: obj[i] });
    return result;
  }

  private arrayToURL(array) {
    const vars = [];
    array.forEach(item => {
      const parts = [];
      item.path.forEach((part, i) => {
        parts.push(i === 0 ? part : '[' + part + ']');
      });

      vars.push([parts.join(''), item.value].join('='));
    });
    return vars.join('&');
  }

  private getParams(path, obj) {
    return path + '?' + this.arrayToURL(this.objToArray(obj, ''));
  }
}
