import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'name' })
export class NamePipe implements PipeTransform {
  transform(values: any = [{}], id: string): any {
    const selectedItem = values.find((item: any) => {
      return (
        id.toString().toLowerCase() ===
        (item.id ? item.id.toString().toLowerCase() : item.value.toString().toLowerCase())
      );
    });
    if (selectedItem) {
      return selectedItem.name || selectedItem.label;
    }
  }
}
