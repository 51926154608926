import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyBtnComponent } from './copy-btn.component';
import {ThemeModule} from '../../../@theme/theme.module';

@NgModule({
  declarations: [CopyBtnComponent],
  imports: [CommonModule, ThemeModule],
  exports: [CopyBtnComponent],
})
export class CopyBtnModule {}
