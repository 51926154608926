import { Injectable } from '@angular/core';

@Injectable()
export class ImgCropperService {
  private selectedImage: Event | null = null

  public setImage(event: Event): void {
    this.selectedImage = event;
  }

  public getEvent(): Event | null {
    return this.selectedImage;
  }
}
