import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-echarts-pie',
  template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsPieComponent {
  options: any = {};

  @Input() set setOptions(options: object) {
    this.options = options;
  }
}
