export enum DepositCustomizationEnum {
  Default = 'Default',
  Logo = 'DEFAULT_LOGO',
  Favicon = 'DEFAULT_FAVICON',
  PageTitle = '10 Coins: Deposit',
}

export enum DepositColorEnum {
  BackgroundColor1 = 'backgroundColor1',
  BackgroundColor2 = 'backgroundColor2',
  ColorHighlight = 'colorHighlight',
  ColorMain = 'colorMain',
  ColorSecondary = 'colorSecondary',
  ColorWarning = 'colorWarning',
}
