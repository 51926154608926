import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['dialog-confirm.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmComponent {
  @Input() title: string;
  @Input() buttonAccept: string;
  @Input() buttonDecline: string;
  @Output() answer = new EventEmitter<boolean>();

  constructor() {}

  close(answer: boolean) {
    this.answer.emit(answer);
  }
}
