import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import {UtcService} from '../services/utc.service';

@Injectable()
export class GetUtcResolver implements Resolve<any> {
  constructor(private utcService: UtcService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.utcService.getUtcSettings();
  }
}
