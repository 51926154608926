import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../@core/services/base-api.service';

export interface MaintenanceConfig {
  template: string;
  maintenance: {
    id: number;
    startTime: string;
    endTime: string;
    isActive: boolean;
    brandIds: string[];
  };
}

@Injectable()
export class MaintenanceService {
  constructor(private router: Router, private api: BaseApiService) {}

  fetch(): Observable<MaintenanceConfig> {
    return this.api.get('handbook/maintenance');
  }

  activate() {
    this.router.navigate(['/maintenance']);
  }
}
