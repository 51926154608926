import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { Page404Component } from './shared/404/page-404.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { IsNotAuthGuardService } from './shared/services/is-not-auth-guard.service';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { MaintenanceResolver } from './shared/maintenance/maintenance.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
    canActivate: [IsNotAuthGuardService],
  },
  {
    path: 'pages',
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: {
      config: MaintenanceResolver,
    },
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
