import { DepositColorEnum, DepositCustomizationEnum } from '../enums/deposit-customization.enum';

export interface IDepositConfig {
  customDepositPage: boolean;
  depositPageSettings: IDepositPageSettings,
}

export interface IDepositPageSettings {
  id: number;
  headerEnable: boolean;
  created: string;
  updated: string;
  merchantId: string;
  backgroundColor1: string;
  backgroundColor2: string;
  colorHighlight: string;
  colorMain: string;
  colorSecondary: string;
  colorWarning: string;
  logo: string;
  font: string;
  favicon: string | null;
  pageTitle: string | null;
}

export interface IDepositPageTemplate {
  id?: number;
  name: string;
  backgroundColor1: string;
  backgroundColor2: string;
  colorHighlight: string;
  colorMain: string;
  colorSecondary: string;
  colorWarning: string;
  headerEnable: boolean;
  logo: string;
  font: string;
  favicon: string;
  pageTitle: string;
}

export interface IDepositCustomizationForm {
  customDepositPage: boolean;
  defaultTemplate: DepositCustomizationEnum;
  [DepositColorEnum.BackgroundColor1]: string;
  [DepositColorEnum.BackgroundColor2]: string;
  [DepositColorEnum.ColorHighlight]: string;
  [DepositColorEnum.ColorMain]: string;
  [DepositColorEnum.ColorSecondary]: string;
  [DepositColorEnum.ColorWarning]: string;
  headerEnable: boolean;
  logo: string;
  font: string;
  templateName?: string;
  favicon: string;
  pageTitle: string;
  customFavicon: true;
  customPageTitle: true;
}

export const LOGO_CROP = 'logo';
export const FAVICON_CROP = 'favicon';

export type CropperType = typeof LOGO_CROP | typeof FAVICON_CROP;
