import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CurrenciesService } from '../../services/currencies.service';

@Component({
  selector: 'ngx-echarts-line',
  template: `
    <div
      echarts
      [options]="options"
      (chartInit)="onChartInit($event)"
      (chartLegendSelectChanged)="legendSelectChange($event)"
    ></div>
  `,
})
export class EchartsLineComponent implements OnChanges {
  public options: any = {};
  private echartsInstance: any;

  @Input() set setOptions(options: object) {
    this.options = options;
  }

  @Input() legendSelect: string;

  @Output() chartLegendSelectChanged = new EventEmitter();

  constructor(private currenciesService: CurrenciesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dispatch(this.currenciesService[this.legendSelect].value);
  }

  onChartInit(event: any) {
    this.echartsInstance = event;
  }

  dispatch(data) {
    if (this.echartsInstance) {
      Object.keys(data).forEach(key => {
        if (!data[key]) {
          setTimeout(
            () =>
              this.echartsInstance.dispatchAction({
                type: 'legendUnSelect',
                name: key,
              }),
            0,
          );
        }
      });
    }
  }

  legendSelectChange(event) {
    this.chartLegendSelectChanged.emit(event);
  }
}
