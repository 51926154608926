import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent {
  public isShown = false;
  public arrOfPage: Array<number>;
  public currentPage = 0;
  private totalPages = 1;
  private totalElements = 0;
  private limit = 0;
  private offset = 0;

  @Output() nextPage = new EventEmitter<number>();

  @Input() set passPageInfo(pageInfo) {
    this.currentPage = pageInfo && pageInfo.page ? pageInfo.page : 0;
    if (pageInfo && pageInfo.totalPages > 1) {
      this.isShown = true;
      this.totalPages = pageInfo.totalPages;
    } else {
      this.isShown = false;
    }
    this.arrOfPage = Array.from(Array(this.totalPages), (x, i) => i + 1);
  }

  @Input() set setTotalElements(totalElement: number) {
    this.totalElements = totalElement;
    this.updatePagination();
  }

  @Input() set setLimit(limit: number) {
    this.limit = limit;
    this.updatePagination();
  }

  @Input() set setOffset(offset: number) {
    this.offset = offset;
    this.updatePagination();
  }

  constructor() {}

  updatePagination() {
    this.totalPages = Math.floor(this.totalElements / this.limit);
    // console.log(this.totalPages);
  }

  setPage(page: number) {
    this.nextPage.emit(page);
  }
}
